import { Usuario } from '../../../modelo/usuario.model';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../../../servicios/auth.service';
import { Component, OnInit, Input, HostListener, ViewEncapsulation, ElementRef, ViewChild, Renderer2, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { Mensajes } from '../../shared/mensajes';
import { environment } from 'src/environments/environment';
import { MenuModel } from 'src/app/modelo/menu.model';
import { isBuffer } from 'util';
import { DialogContrasenaComponent } from '../../dialog-contrasena/dialog-contrasena.component';
import { MatDialog } from '@angular/material/dialog';
// import * as firebase from 'firebase';
// import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild('loginTemplate', { static: true }) loginTemplate: ElementRef;
  @ViewChild('formulario', { static: true }) formularioTemplate: ElementRef;
  @ViewChild('pass', { static: true }) inputPassword: ElementRef;


  @Input() ruta = 'inicio';
  @Input() tipopresentacion;
  logueando = false;
  $usuario: Observable<Usuario>;
  formLogin: FormGroup;
  userid;
  listaMenus: any;
  error = '';
  rutaParamentro;
  loading = false;
  email: string;
  cuit: string;
  password: string;
  userSistema = true;
  recordarUsuario = false;
  // usuarioActual: Usuario;
  typeInput = 'tex'
  mostrarPassword = false;
  comercio: boolean;
  proveedor: boolean;
  bienvenido: boolean;
  mostrarForms = true;
  procesando = false;
  subcription = new Subscription()
  nombreRuta: string;
  mostrarForm = false;
  logueandose = new Subscription();
  constructor(private router: Router,
    private routeActiva: ActivatedRoute,
    private auth: AuthService,
    public msj: Mensajes,
    private dialogo: MatDialog,
    private render: Renderer2
  ) {
  }

  alto: number;
  // @ViewChild('hojaBlanca', { static: false }) hojaBlanca: ElementRef;
  @HostListener('window:resize', ['$event']) detectarPantalla(event?) {

    if (window.innerWidth < 768) {
      this.alto = window.outerHeight; // alto del navegador MOBILE
    } else {
      this.alto = window.innerHeight; //  alto del navegador PC
    }
  }

  ngOnInit() {
    this.detectarPantalla();
    this.determinarLogin();
    this.initForm();
    this.subcribirseEstadoLogin();
    this.autocompletarUser();
  }

  ngOnDestroy(){
    this.auth.cambiarValorLogging(false)
    this.subcription.unsubscribe();
  }

  subcribirseEstadoLogin(){
    this.subcription.add(
      this.auth.logging$.subscribe(
        estadoLogging => {
          this.logueando  = estadoLogging;
          //console.log(this.logueando)
        }
      )
    )
  }

  autocompletarUser(){
   localStorage.getItem('guardarUsusario') ?this.recordarUsuario = true: this.recordarUsuario = false;
   this.recordarUsuario ? this.typeInput = 'text':this.typeInput = 'text';
  }

  determinarLogin() {
    this.subcription.add(
      this.routeActiva.params.subscribe(
        parametro => {

          if(!this.logueando){
          this.nombreRuta = parametro["nombre"];
          //console.log("ENTRO EN RUA", this.nombreRuta);

          switch (this.rutaParamentro !== '' ? this.nombreRuta?.toLowerCase() : '') {
            case 'proveedor':
              this.comercio = false;
              this.proveedor = true;
              this.userSistema = false;
              this.bienvenido = false;
              break;
            case 'admin':
              this.comercio = false;
              this.proveedor = false;
              this.userSistema = true;
              this.bienvenido = false;
              break;
              case 'comercio':
                this.comercio = true;
                this.proveedor = false;
              this.userSistema = false;
              this.bienvenido = false;
              break;
            default:
              this.comercio = false;
              this.proveedor = false;
              this.userSistema = false;
              this.bienvenido = true;
              break;
          }
          if (!this.userSistema) { //  si no es admin pone el fondo

               if(this.comercio){// saca fondo proveedor y pone fondo comercio
                this.render.addClass(this.loginTemplate.nativeElement, 'fondoComercio')
                this.render.removeClass(this.loginTemplate.nativeElement, 'fondoProveedor')

              }else if(this.proveedor){// saca fondo comercio y pone fondo proveedor
                this.render.removeClass(this.loginTemplate.nativeElement, 'fondoComercio')
                this.render.addClass(this.loginTemplate.nativeElement, 'fondoProveedor')

              }else{
                this.render.removeClass(this.loginTemplate.nativeElement, 'fondoComercio')
                this.render.removeClass(this.loginTemplate.nativeElement, 'fondoProveedor')
               }


            //console.log("fondo comercio", this.comercio)
          }
          else{
            // saca los 2 fondos
            //console.log("saca los 2 fodos")
            this.render.removeClass(this.loginTemplate.nativeElement, 'fondoComercio') ;
            this.render.removeClass(this.loginTemplate.nativeElement, 'fondoProveedor')
          }
          }

        }
      )
    );


  }

  recordarUser(){
    this.recordarUsuario  = !this.recordarUsuario;

  }

  actualizarInfoUser(){
    this.recordarUsuario ?  localStorage.setItem('guardarUsusario','guardarUsusario') : localStorage.removeItem('guardarUsusario');
  }


  mostrarPass() {
    this.mostrarPassword = !this.mostrarPassword;
    if(this.mostrarPassword){
      this.typeInput = "text"
      this.render.setStyle(this.inputPassword.nativeElement,'font-family', 'Roboto')
      this.render.setStyle(this.inputPassword.nativeElement,'-webkit-text-security', 'unset')
    }else{
      this.render.removeStyle(this.inputPassword.nativeElement,'font-family')
      this.typeInput = "text"
    }
  }

  initForm() {
    this.formLogin = new FormGroup({
      cuitCuil: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    });
  }

  login(tipo?: string) {

    if (this.formLogin.valid) {
      this.actualizarInfoUser() // si tildo el check lo guarda en el local, o sino lo remueve
      let user: any = {};
      if (this.userSistema) {
        // es un administrador
        user = this.formLogin.value
        // console.log(this.formLogin.value)
        this.auth.login(user,'ADMIN');
      } else {
        // es comercio o proveedor
         user.password = this.formLogin.controls.password.value
         if(this.comercio){
          user.cuitCuil = 'CR' + this.formLogin.controls.cuitCuil.value;
          this.auth.login(user,'COMERCIO');
         }else{
          user.cuitCuil = 'PR' + this.formLogin.controls.cuitCuil.value;
          this.auth.login(user,'PROVEEDOR');
         }
      }
      //console.log(user) // puede ser ADMIN PM, PROVEEDOR o COMERCIO
    } else {
      this.msj.mensaje('Faltan cargar datos para poder ingresar al sistema', 'Cerrar', 'msj', 3000);
    }
  }


  resetPassword() {
    const titulo = "Restablecer contraseña";
    const msj = "Ingrese el correo con el que realizó el registro de su DNI y contraseña";
    const accion = false;
    const dialogRef = this.dialogo.open(DialogContrasenaComponent, {
      panelClass: 'claseDialogo',
      data: { accion, titulo, msj }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        if (result.accion) {

        }

      }
    });
  }


  registrarme() {
    this.comercio ? this.router.navigate([`/productosmisioneros/registrarcomercio`]) : this.router.navigate([`/productosmisioneros/registrarproveedor`])
  }

  cargarComercio() {
    this.router.navigate([`/inicio/comercio`]);
  }

  cargarProveedor() {
    this.router.navigate([`/inicio/proveedor`]);
  }

}
