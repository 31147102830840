
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';


const materialModules = [
  MatIconModule,
  MatButtonModule,
  MatFormFieldModule,
  MatSnackBarModule,
  MatInputModule,
  MatTooltipModule,
  MatDialogModule

];


@NgModule({
  declarations: [
  ],
  imports: [
    materialModules,
    CommonModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
  ],
  providers: [
  ],
  exports: [
    // materialModules,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
  ],

})
export class SharedMainModule { }

