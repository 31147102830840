import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/servicios/auth.service';
import { Mensajes } from '../shared/mensajes';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SpinnerComponent } from '../shared/spinner/spinner.component';
import { ProveedorService } from 'src/app/servicios/proveedor.service';
import { ProveedorModel } from 'src/app/modelo/proveedor.model';
import { Usuario } from 'src/app/modelo/usuario.model';

@Component({
  selector: 'app-dialog-contrasena',
  templateUrl: './dialog-contrasena.component.html',
  styleUrls: ['./dialog-contrasena.component.scss'],
  providers: [SpinnerComponent]
})
export class DialogContrasenaComponent  implements OnInit {
  tipoDialogo = '';
  titulo = '';
  msj = '';
  preguntar  = true;
  reseteada = false;
  formulario: FormGroup;
  procesando: boolean = false;
  userTemporal: Usuario;
  proveedorTemporal: ProveedorModel;

  constructor(public dialogRef: MatDialogRef<DialogContrasenaComponent>,
    private auth: AuthService,
    private msjs: Mensajes,
    private proveedorService: ProveedorService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.tipoDialogo = this.data.tipo;
    this.msj = this.data.msj;
    this.titulo = this.data.titulo;
    this.proveedorTemporal = this.auth.getProveedor;
    this.initForm();
    // this.servicio = this.data.servicio
  }

  initForm(){
    this.formulario = new FormGroup({
      email: new FormControl('',[ Validators.required, Validators.email]),
      // newPass: new FormControl('',[ Validators.required]),
      // confirmPass: new FormControl('',[ Validators.required]),
      // password2: new FormControl('', Validators.required),
    })
  }

  restablecer(){

    if(this.formulario.invalid){
      this.formulario.markAllAsTouched();
      this.msjs.mensaje("Ingrese el correo con el que se registró", "Cerrar", "C", 3500);
      return;
    }
    this.procesando = true;
    // console.log(this.formulario.value)
    // ejecuta el metodo para restablecer la password
    this.auth.peticionRestablecerPassword(this.formulario.controls.email.value).subscribe(
      data => {
        this.reseteada = true;
        this.dialogRef.close()
        this.procesando = false;
        this.msjs.mensaje("Petición enviada a su correo. Podrá restablecer su contraseña mediante el enlace que fue enviado a su correo", "Cerrar", "ok", 12000);
      },
      erro => {
        this.reseteada = false;
       this.procesando = false;
       this.msjs.mensaje(erro.error, "Cerrar", "C", 4000);
      },

    )

  }

  reintentar(){
    this.preguntar = true;
    this.restablecer()
  }

  confirmar(valor: boolean) {
    this.dialogRef.close({
      accion: valor
    })
  }

}
