import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ServicesMSPService } from './abstractCRUD.service';
import { ProveedorModel } from '../modelo/proveedor.model';
import { Observable } from 'rxjs';
import { PedidoModel } from '../modelo/pedido.model';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})

export class ProveedorService extends ServicesMSPService<ProveedorModel> {
  constructor(private http2: HttpClient) {
    super(http2, '/proveedor');
  }

  getByUser(id: number): Observable<ProveedorModel> {
    let url = this.urlBase + '/byUser/' + id.toString();
    return this.http2.get<ProveedorModel>(url);
  }
   getByCuit(cuit: number): Observable<ProveedorModel> {
    let url = this.urlBase + '/byCuit/' + cuit.toString();
    return this.http2.get<ProveedorModel>(url);
  }

  getCertificado(id: number): Observable<Blob>  {
    let url = environment.urlBase+ environment.vApi +'/report/certificadoProveedor/' + id.toString();
    return this.http2.get(url, { responseType: 'blob' });
  }



  getOfertas(id: number): Observable<PedidoModel> {
    let url = this.urlBase + '/ofertas/' + id.toString();
    return this.http2.get<PedidoModel>(url);
  }

  deleteProveedor(id: number, proveedor: ProveedorModel){
    let url = this.urlBase + '/darBaja/' + id.toString();
    //console.log(url)
    return this.http2.put<ProveedorModel>(url, proveedor);
  }

}
