// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
   urlBase: 'https://stage.pm.acmci.misiones.gob.ar',
   urlBaseSom: 'https://stage.som.misiones.gob.ar',
  // urlBase: 'localhost:18081',
  nombreSistema: 'productos-misioneros',
  estadoAPI:'/municipio',
  // estadoAPI:'/actuator/healt',
  intervaloEstadoAPI: 'DEV',
  vApi: '/api',
  urlReporte: 'https://stage.pm.acmci.misiones.gob.ar/reporte',
  apiKey360:{
    EducControlAgrotoxico: 'OGI0MjQxMTYyNmM5MTQwMTQ5NWZlNzk0NzJjMmVlY2JjNTE2ODYwM2U5OGM2ZjI5NTE2N2FiMWU0Mjc5ZTliOQ',
    FomentoAreasNatProtegidas: 'M2VlYzI1ODhiZjllNjNkNGVhMjBkODNiODdkNWEzNmEyNTJjOTlmMmUxN2UyNTc3ZWVjMDZmMjE2YTg3MmRmNg',
    ForestalProvincial: 'ZDI5ZGFjNTc0MjNhNjM0MzUwZmVmNmJiMzJkYTM3ZmQ0YjYxNTRkODY1ZGY3NmUzMjE1MTdmMmE1OTgyNTAyMQ',
    TierrasFiscales: 'MWJlZmMzNjAxMGI2ZTNkMWFjNmM0NDY0YzJjZDFjZDFlZTNiZDYxNjQ2YmUxMjU1MmM0Mzc3ZjhmYTVlYTlhYw'
  },
  url360: "https://sandboxapi.pagos360.com",
  urlRenaper: "https://apirenaper.idear.gov.ar"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
