<div id="layoutExterno">
  <div id="barraComponente">


    <img src="../../../assets/logoPrincipal-b.png" alt="" id="logoBoleta">

  </div>
  <div class="contenedor" [style.min-height.px]="alto" >
      <router-outlet></router-outlet>
  </div>

  <div id="footerComponente">
    <div class="row">
      <div class="col-12 text-right">
       <div class="enLinea">
        <img src="../../../assets/marandu.png" alt="" id="loguito">
        <p class="negro letraFooter copy ml-3">@TODOS LOS DERECHOS RESERVADOS 2020.</p>
       </div>
      </div>
    </div>

  </div>
</div>

