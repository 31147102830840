import { Usuario, PermisoModel } from 'src/app/modelo/usuario.model';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, Route, ActivatedRoute } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { SessionService } from './session.service';
import { Mensajes } from '../components/shared/mensajes';
import { MenuModel } from '../modelo/menu.model';
import { ComercioService } from './comercio.service';
import { ProveedorService } from './proveedor.service';
import { ComercioModel } from '../modelo/comercio.model';
import { ProveedorModel } from '../modelo/proveedor.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  urlBase: string;
  token: string;
  usuario: Usuario = {};
  estaLogueado: boolean;
  listaMenus: MenuModel[] = [];
  public usuarioActual$: BehaviorSubject<Usuario>;
  public currentUser: Observable<Usuario>;
  public EstadoLogin: BehaviorSubject<boolean>;
  public logging$: Observable<boolean>;



  constructor(private router: Router,
    private aRoute: ActivatedRoute,
    private http: HttpClient,
    private msj: Mensajes,
    private comercioService: ComercioService,
    private proveedorService: ProveedorService,
    private sessionService: SessionService) {
    this.urlBase = environment.urlBaseSom + '/api-auth';
    this.usuarioActual$ = new BehaviorSubject<Usuario>(this.sessionService.getUser('usuario'));
    this.currentUser = this.usuarioActual$.asObservable();

    this.EstadoLogin = new BehaviorSubject<boolean>(false);
    this.logging$ = this.EstadoLogin.asObservable();
  }


  // METODOS GET ///

  public get getUsuarioActual(): Usuario {
    if (this.sessionService.getUser('usuario') !== null && this.sessionService.getUser('usuario') !== undefined) {
      return this.usuarioActual$.value;
    } else {
      this.logout();
    }
  }

  public get getComercio(): ComercioModel {
    if (this.sessionService.getUser('usuario') !== null && this.sessionService.getUser('usuario') !== undefined) {
      return this.usuarioActual$.value.comercio;
    }
  }

  public get getPermisos(): PermisoModel[] {
    if (this.sessionService.getUser('usuario') !== null && this.sessionService.getUser('usuario') !== undefined) {
      return this.usuarioActual$.value.perfiles[0].roles[0].permisos;
    }
  }

  public get getProveedor(): ProveedorModel {
    if (this.sessionService.getUser('usuario') !== null && this.sessionService.getUser('usuario') !== undefined) {
      return this.usuarioActual$.value.proveedor;
    }
  }


  public get getToken(): string {
    if (this.usuarioLogueado) {
      return this.usuarioActual$.value["token"] ? this.usuarioActual$.value["token"] : null
    }

  }

  public get getIdUsuario(): number {
    if (this.usuarioLogueado) {
      return this.usuarioActual$.value["idUsuario"] ? this.usuarioActual$.value["idUsuario"] : null
    }
  }

  public get getTipoUsuario(): string {
    if (this.usuarioLogueado) {
      return this.usuarioActual$.value["perfiles"][0]?.['roles'][0]?.nombre ? this.usuarioActual$.value["perfiles"][0]['roles'][0].nombre : null
    }
  }

  public get getSistema(): string {
    if (this.usuarioLogueado) {
      return this.getUsuarioActual?.perfiles[0]?.sistema?.nombre;
    }
  }

  public get getRol(): string {
    if (this.usuarioLogueado) {
      return this.getUsuarioActual?.perfiles[0]?.roles[0]?.nombre;
    }

  }

  ///ESTADO DEL LOGUIN ///


  public get valorLogging(): boolean {
    return this.EstadoLogin.value;
  }

  cambiarValorLogging(valor: boolean) {
    this.EstadoLogin.next(valor);
  }




  usuarioLogueado(): boolean {
    if ((this.sessionService.getUser('usuario') !== null && this.sessionService.getUser('usuario') !== undefined)) {
      return true;
    } else {
      // this.logout();
      return false;
    }
  }

  refrescarUsuario(user: Usuario) {
    this.sessionService.setUser('usuario', user);
  }

  public tienePermiso(permiso: string): boolean {
    let poseePermiso: boolean;
    this.getUsuarioActual.perfiles[0].roles[0].permisos.forEach(x => x.nombre === permiso ? poseePermiso = true : '');
    return poseePermiso;
  }

  // INICIAR SESION
  login(user: Usuario, tipoUsuario: string) {
    this.cambiarValorLogging(true);
    this.getAuth(user).subscribe(
      data => {
        if (data.validEmail) {
          this.sessionService.setUser('usuario', data); // ACTUALIZA EL USUARIO EN EL SESSION STORAGE

          let comercio;
          tipoUsuario === 'COMERCIO' ? comercio = true : comercio = false;

          //console.log(this.getUsuarioActual, "ENTRO EN EL LOGIUNNNN")
          let index = this.getUsuarioActual?.perfiles.findIndex((perfil) => { return perfil?.sistema?.nombre === environment.nombreSistema })     // comprobar si tiene un perfil para este sistema
          if (index !== -1) {

            if (this.getRol === 'PROVEEDOR') {
              this.setProveedor(data);

              comercio ? // comprueba que este queriendo ingresar como comercio desde el login correcto
                this.msj.mensaje("Compruebe la dirección de inicio de sesión. Ud está queriendo ingresar con un perfil de PREVEEDOR.", 'Cerrar', 'msj', 5000) :
                this.navegarAlPrimerMenu(index); // esta todo ok e ingresa al sistema
              return;
            }

            if (this.getRol === 'COMERCIO') {
              this.setComercio(data);
              comercio ? // comprueba que este queriendo ingresar como comercio desde el login correcto
                this.navegarAlPrimerMenu(index) :// esta todo ok e ingresa al sistema
                this.msj.mensaje("Compruebe la dirección de inicio de sesión. Ud está queriendo ingresar con un perfil de PREVEEDOR.", 'Cerrar', 'msj', 5000);
              return;
            }

            if (this.getRol !== 'COMERCIO' && this.getRol !== 'PROVEEDOR') { // ADMIN
              //console.log("entro user SISTEMA PARTE ADMIN")
              this.navegarAlPrimerMenu(index)
              return;
            }

          } else {
            this.msj.mensaje("Su cuenta no posee permisos para acceder al sistema", 'Cerrar', 'msj', 4000);
          }

          this.cambiarValorLogging(false);
        } else {
          this.msj.mensaje("Su cuenta requiere activación. Revise su correo electrónico y acceda al enlace de activación.", 'OK', 'ok', 10000);
          this.cambiarValorLogging(false);
        }

      },
      error => {
        error.status === 401 ?
          this.msj.mensaje("Usuario o contraseña incorrectos", 'Cerrar', 'msj', 4000) :
          this.msj.mensaje("No se ha podido acceder al servicio, inténtelo nuevamente más tarde.", 'Cerrar', 'msj', 4000);
        this.cambiarValorLogging(false);
      });
  }

  setProveedor(user: Usuario) {

    this.proveedorService.getByUser(user.idUsuario).subscribe(
      proveedor => {
        user.proveedor = proveedor;
        this.guardarUser(user);
      },
      error => { }
    )
  }

  setComercio(user: Usuario) {
    // console.log()
    this.comercioService.getByUser(user.idUsuario).subscribe(
      comercio => {

        user.comercio = comercio;
        // console.log(user, "esto se va a guardar")
        this.guardarUser(user);
      },
      error => { }
    )
  }

  navegarAlPrimerMenu(indexPerfil: number) {
    // por ahora inicia en el rol 0 por default y se le asignan esos menus, hay q implementar un dialog
    // para q le permita seleccionar roles en caso de tener varios.
    let menus: MenuModel[] = this.getUsuarioActual?.perfiles[indexPerfil].roles[0].menus;

    if (menus.length > 0) {
      const rutaLimpia = this.limpiarEspacioAcentos(menus[0].nombre);
      this.listaMenus = menus;
      if (menus.find(menu => menu.nombre.toUpperCase() === 'TABLERO') !== undefined) {
        // this.router.navigate([`/sistema/tablero`]);
        // this.logueando = false;
      } else {
        this.configurarRuta(); // puede que tenga subRuta o sea directo
      }
    } else {
      // this.logueando = false;
      this.msj.mensaje('El usuario no posee ningún menu habilitado.', 'Cerrar', 'C', 3000);
    }
  }

  configurarRuta(): any {
    const menu = this.listaMenus[0]; // primer menu
    let rutaDefault: string;

    // RUTA DEFAULT
    switch (this.getRol) {
      case 'PROVEEDOR':
        rutaDefault = 'proveedor'
        break;
      case 'COMERCIO':
        rutaDefault = 'comercio'
        break;
      default:
        rutaDefault = 'configuracion'
        break;
    }

    // RUTA DINAMICA
    let rutaMenu = this.limpiarEspacioAcentos(menu.nombre); // ruta base sin acentos ni mayusculas
    menu?.menuPadre ? rutaMenu = `${this.limpiarEspacioAcentos(menu.menuPadre.nombre)}/${rutaMenu}` : null;
    // SI TIENE RUTA DEFAULT ENTRA AHI; SINO APLICA RUTA DINAMICA
    //console.log("RUTA FINAL ", rutaDefault);
    rutaDefault ? this.router.navigate([`/sistema/${rutaDefault}`]) : this.router.navigate([`/sistema/${rutaMenu}`]);
  }

  limpiarEspacioAcentos(ruta: string) {
    const menuSinEspacios = ruta.replace(/\s/g, '');
    const sinEspacioNiacentos = menuSinEspacios.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return sinEspacioNiacentos.toLowerCase();
  }

  guardarUser(user: Usuario) {
    this.sessionService.deleteUser('usuario');
    this.sessionService.setUser('usuario', user);
    this.usuarioActual$.next(user);
  }

  // GUARDIAN DE LAS RUTAS
  canActivate(ruta: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log("imprimiendo stage");
    console.log(state);

    let rutaFraccionada: String[] = [];
    rutaFraccionada = state["url"].split('/', 5);
    console.log("rutaFraccionada");
    console.log(rutaFraccionada);

    const path = rutaFraccionada[rutaFraccionada.length - 1]; // el último tramo
    // console.log("ENTRO EN CAN ACTIVATE", path)
    const confOseguridad = rutaFraccionada[2]; // es configuracion o seguridad

    if (!this.getUsuarioActual.token !== null) {

      let encontroMenu: boolean;
      this.getUsuarioActual.perfiles[0].roles[0].menus.find(menu => {

        const menuSinEspacios = menu.nombre.replace(/\s/g, '');
        const sinEspacioNiacentos = menuSinEspacios.normalize('NFD').replace(/[\u0300-\u036f]/g, '');


        if (sinEspacioNiacentos.toLowerCase() === path ||
          sinEspacioNiacentos.toLowerCase() === confOseguridad ||
          'perfilusuario' === path) {

          encontroMenu = true;

        }
        console.log("sinEspacioNiacentos");

        console.log(sinEspacioNiacentos);
        if (sinEspacioNiacentos.toLowerCase() === "proveedores") {
          encontroMenu = true;

        }

      });


      if (encontroMenu) {

        // COMERCIO
        if (this.getRol === 'COMERCIO') {
          switch (path) {
            case 'ofertas':
              // CONTROL DE COMERCIO PARA Q NO ENTRE EN LAS OFERTAS SIN TNR COMERCIO
              if (this.getComercio?.id) {
                return true;
              } else {
                this.msj.mensaje('Debe cargar los datos de su comercio para empezar a publicar ofertas', 'Cerrar', 'C', 5500);
                return false
              }
              break;
            default:
              return true;
              break;
          }
        }

        // PROVEEDOR
        // control ofertas y productos
        if (this.getRol === 'PROVEEDOR') {
          switch (path) {
            case 'productos':
              if (this.getProveedor?.id) {
                return true
              } else {
                this.msj.mensaje('Debe cargar los datos como proveedor para empezar a publicar sus productos', 'Cerrar', 'C', 5500);
                return false;
              }
              break;
            case 'ofertas':
              if (this.getProveedor?.id) {
                return true;
              } else {
                this.msj.mensaje('Debe cargar los datos como proveedor para empezar ver las ofertas', 'Cerrar', 'C', 5500);
                return false;
              }
              break;

            default:
              return true;
              break;
          }
        }

        if (this.getRol && this.getRol !== 'PROVEEDOR' && this.getRol !== 'COMERCIO') { // TIENE ALGUN ROL CON EL MENÚ, PUEDE SER ADMIN; OPERADOR; ETC. SE TOMA EL MENU COMO PERMISO
          return true;
        } else { // NO TIENE ROL,
          this.getRol !== 'PROVEEDOR' && this.getRol !== 'COMERCIO' ? // si no es un comercio o un proveedor, no tiene ROL
            this.msj.mensaje('No posee ningún ROL para acceder al sistema', 'Cerrar', 'C', 3500) : null;
          return false;
        }

      } else {
        this.msj.mensaje('No posee los permisos para acceder a ésta ruta', 'Cerrar', 'C', 3500);
        return false;
      }
    } else {
      return false;
    }
  }

  // METODO CERRAR SESION
  logout() {
    switch (this.getRol) {
      case 'COMERCIO':
        this.router.navigate(['inicio/comercio']);
        break;
      case 'PROVEEDOR':
        this.router.navigate(['inicio/proveedor']);
        break;
      default:
      case 'ADMIN':
        this.router.navigate(['inicio/admin']);
        break;
        break;
    }
    sessionStorage.clear();
    this.estaLogueado = false;
    this.cambiarValorLogging(false)
  }


  ////SERVICIOS//////


  // METODO AUTENTICACIÓN
  getAuth(user: any): Observable<Usuario> {
    return this.http.post<Usuario>(`${this.urlBase}/auth/login`, user)
      .pipe(map(user => {
        if (user.token !== null) {
          // AHORA SI VIENE EL USUARIO Y LO GUARDO
          this.guardarUser(user);
        }
        return user;
      })
      );
  }

  // METODO POST PARA // 1 PROVEEDOR 2 COMERCIO
  postUsuarioPM(user: Usuario, id: number) {
    return this.http.post<Usuario>(`${this.urlBase}/usuario-externo/pm/${id}`, user)
      .pipe(map(user => {
        // console.log("NO GUARDA", user)
        if (user.token !== null) {
          // ACTUALMENTE VIENE UN MSJ NO CONVIENE GUARDAR EL USUARIO ACA
          // this.sessionService.setUser('usuario', user);
          // this.usuarioActual$.next(user);
        }
        return user;
      })
      );
  }







  // PETICION PARA RESET PASWORD
  peticionRestablecerPassword(correo: string): Observable<any> {
    let parametros = new HttpParams()
    parametros = parametros.append('email', correo)
    return this.http.get(`${this.urlBase}/usuario-externo/sendRestorePass`, { params: parametros })
      .pipe(take(1));
  }
}
