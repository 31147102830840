import { BrowserVersionService } from '../shared/advertencia-browser/browserVersion.service';
import { Component, OnInit, HostListener, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-layout-externo',
  templateUrl: './layout-externo.component.html',
  styleUrls: ['./layout-externo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LayoutExternoComponent implements OnInit {
  alto: number;
  @HostListener('window:resize', ['$event']) detectarPantalla(event?) {

    if (window.innerWidth < 768) {
      this.alto = window.outerHeight - 160; // alto del navegador MOBILE
    } else {
      this.alto = window.innerHeight - 100; //  alto del navegador PC
    }

  }

  estaEnCupon: boolean;

  constructor( private browserVersion: BrowserVersionService) { }

  ngOnInit() {
    this.detectarPantalla();

    this.browserVersion.comprobarVersion();

    // this.variablesSalir.estaCupon$.subscribe(
    //   data => {this.estaEnCupon = data;
    // }
    // );

  }


}
