import { Component, AfterViewInit, Renderer2, OnInit, ViewEncapsulation } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { EstadoAPIService } from './servicios/estadoAPI.service';
import {  Observable, timer } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnInit {
  title = 'Programa Productos Misioneros';
  envi = environment.production;
  apiON: boolean;
  api$ : Observable<any>
  updating = false;
  offline = false;
  texto: string;

  constructor(
    public updates: SwUpdate,
    private renderer: Renderer2,
    private estadoAPIservice: EstadoAPIService,
    ) {
    updates.available.subscribe(event => {});
    updates.activated.subscribe(event => {});
    updates.available.subscribe(event => {
        updates.activateUpdate().then(() => document.location.reload());
    });
   }


   ngAfterViewInit() {
     let loader = this.renderer.selectRootElement('#loader');
     this.renderer.setStyle(loader, 'display', 'none');
   }

   ngOnInit():void{
    this.escucharAPI();
  }

   escucharAPI(){
     let intervalo;

     switch (environment.intervaloEstadoAPI) {
       case 'DEV':
         intervalo = 250 // cada 15 segundos
         break;
       case 'STAGE':
          intervalo = 500 // cada 30 segundos
          break;
       default:
         break;
     }

    if(!environment.production){
      timer(0, intervalo * 60).subscribe(x => {
     this.consultarMUnicipio()
      });
    }
  }

  scrollTop(event) {
    window.scroll(0,0);
  }



  consultarMUnicipio(){
    this.estadoAPIservice.getAll().subscribe(
      estado => {
        this.apiON = true;
        this.updating = false;
        this.offline = false;
        this.texto = 'Modo desarrollo'
      },
      error => {
        switch (error?.status) {
          case 502:  case 0:
            this.updating = true
            this.apiON = false;
            this.offline = false;
            this.texto = 'Actualizando'
            break;
            case 200:
              this.updating = false
              this.apiON = true;
              this.offline = false;
              this.texto = 'Modo desarrollo'
              break;
          default:
            this.updating = false
            this.apiON = false;
            this.offline = true;
            this.texto = 'Sin internet'
            break;
        }

        //console.log(error?.status)
      }
    )
  }
}
