import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { take} from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {PaginadorModel,ResponsePaginatorModel }from '../modelo/paginador.model';
import { Filtro } from '../modelo/filtros.model';



export abstract class ServicesMSPService<T> {
  urlBase: string;
  constructor(private http: HttpClient, private urlServicio: string) {
    this.urlBase = `${environment.urlBase}${environment.vApi}${this.urlServicio}`;
  }

  getAllPaginated(paginador?: PaginadorModel, filtros?: Filtro ): Observable<ResponsePaginatorModel> {
    let parametros = new HttpParams()
    if (paginador) {
      parametros = parametros.append('page', paginador.page.toString())
      parametros = parametros.append('size', paginador.size.toString())
    }

    for (const filtro in filtros) {
      filtros[filtro] != "" ? parametros = parametros.append(`${filtro}`, filtros[filtro]): null;
    }


    return this.http.get<any>(`${this.urlBase}/see_all`, { params: parametros }).
      pipe(
        take(1)
      );
  }

  getAll(): Observable<T[]> {
    return this.http.get<T[]>(`${this.urlBase}/see_all`).
      pipe(
        take(1)
      );
  }

  getOne(id: number): Observable<T> {
    return this.http.get<T>(`${this.urlBase}/see/${id}`).
      pipe(
        take(1)
      );
  }


  add(objeto: T): Observable<T> {
    return this.http.post<T>(`${this.urlBase}/generate`, objeto)
      .pipe(
        take(1)
      );
  }

  edit(id: number, objeto: T): Observable<T> {
    return this.http.put<T>(`${this.urlBase}/amend/${id}`, objeto)
      .pipe(
        take(1),
      );
  }

  editGrupo(objeto: T): Observable<T> {
    return this.http.put<T>(`${this.urlBase}/amend`, objeto)
      .pipe(
        take(1),
      );
  }

    delete(id: number): Observable<T> {
      return this.http.delete<T>(`${this.urlBase}/destroy/${id}`)
      .pipe(
        take(1),
      );
    }


}



