import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrowserVersionService {

  advertencia: BehaviorSubject<boolean>;
  public advertencia$: Observable<boolean>;
  constructor() {
    this.advertencia = new BehaviorSubject<boolean>(false);
    this.advertencia$ = this.advertencia.asObservable();
   }

   comprobarVersion(){

    // tslint:disable-next-line: max-line-length
    const userAgent = navigator.userAgent,
    matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    // tslint:disable-next-line: radix
    if (matchTest[1] === 'Chrome' && parseInt(matchTest[2]) < 50  ) {
      this.advertencia.next(true);
    }

    // tslint:disable-next-line: radix
    if (matchTest[1] === 'Firefox' && parseInt(matchTest[2]) < 52  ) {
      this.advertencia.next(true);
    }


    //console.log(matchTest[1], matchTest[2]);
   }


  mostrarAdvertencia(valor: boolean) {
    this.advertencia.next(valor);
  }
}
