import { SharedMainModule } from './components/shared/sharedMain.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UpdateService } from './servicios/updateSW.service';
import { UrlBaseInterceptor } from './servicios/interceptor.service';
import { MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from './components/shared/custom-date-adaptar';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedMainModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  bootstrap: [AppComponent],
  providers: [
    {
     provide: MAT_DATE_LOCALE,
     useValue: 'es-ES'
    },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    // AuthService,
    UpdateService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UrlBaseInterceptor,
      multi: true
    }
  ],
})
export class AppModule {

 }
