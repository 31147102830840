import { Mensajes } from './../components/shared/mensajes';
import { Usuario } from './../modelo/usuario.model';
import { catchError, map, mapTo } from 'rxjs/operators';
import { Observable, throwError, of, fromEvent } from 'rxjs';
import { Injectable, OnInit } from '@angular/core';
import { HttpHandler, HttpRequest, HttpInterceptor, HttpEvent, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { merge } from 'rxjs';
// import { Observable } from 'rxjs/Observable';
// import 'rxjs/Rx';
import { AuthService } from './auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UrlBaseInterceptor implements HttpInterceptor, OnInit {

  datosUser: Observable<Usuario>;
  // usuario: Usuario = {};
  token: string;
  online$: Observable<boolean>;
  updated$: Observable<boolean>;
  conexion: boolean;

  constructor(public auth: AuthService, private _snackBar: MatSnackBar, private msj: Mensajes) {

    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true), map(() => true)),
      fromEvent(window, 'offline').pipe(mapTo(false), map(() =>  false))
    );


  }

  ngOnInit(){
    // this.online$.subscribe(
    //   data  => {
    //     this.conexion = data;
    //     console.log(this.conexion)
    //   }
    // );
  }


  intercept(req: HttpRequest<any>, next: HttpHandler, ): Observable<HttpEvent<any>> {

    this.auth.usuarioLogueado() ? this.token = this.auth?.getToken : null
    // if (this.conexion) { // tiene conexion a internt
      if (this.token &&
        !req.url.includes(environment.url360) &&
        !req.url.includes("http://150.136.1.69:8011") &&
        !req.url.includes(environment.urlRenaper)) {
        // console.log(this.token, "TIENE TOKEN")
        const headers = new HttpHeaders({
          // 'Content-Type': '*',
           Authorization: 'Bearer ' + this.auth?.getToken
        });


         // CLONO LA REQUEST Y LE INYECTO LA CABECERA
        const reqClone = req.clone({ headers });

        return next.handle(reqClone).pipe(
          catchError(this.handleError)
        );
      } else {
        return next.handle(req).pipe(
          catchError(this.handleError)
        );
      }
    // } else { // no está conectado a internt
    //   environment.production ?
    //   this.msj.mensaje('Compruebe su conexión a internet, no es posible conectarse al servidor. Vuelva a recargar la página cuando tenga conexión.', 'Cerrar', 'E', 3500):
    //   null;

    //   return next.handle(req).pipe(catchError(this.handleError));
    // }
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // Error en cliente o falta.
      // console.log("user", error)
      // console.error('Error del lado cliente: ', error.statusText);
      return throwError(error);
    } else {
      // Respuesta del Backend
      // console.error(
        // console.log("back", error)
      //   `Mensaje del Backend = Código: ${error.status}, Descripción: ${error.statusText}`);
      //console.log("cobexuib es ", this.conexion)
      // return throwError(!this.conexion ? null : error);
      return throwError(error);

    }
  }
}
