import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-pagina-no-encontrada404',
  templateUrl: './pagina-no-encontrada404.component.html',
  styleUrls: ['./pagina-no-encontrada404.component.scss']
})
export class PaginaNoEncontrada404Component implements OnInit {

  constructor() { }
  alto: number;
  // @ViewChild('hojaBlanca', { static: false }) hojaBlanca: ElementRef;
  @HostListener('window:resize', ['$event']) detectarPantalla(event?) {

    if (window.innerWidth < 768) {
      this.alto = window.outerHeight; // alto del navegador MOBILE
    } else {
      this.alto = window.innerHeight; //  alto del navegador PC
    }
  }

  ngOnInit() {
    this.detectarPantalla();
  }

}
