import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { Subject, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  public sessionTimer$ = new Subject();

  public tiempo = 1; // minutos

  constructor( private storageService: StorageService) { }
  // Set the json data to local
  setUser(key: string, value: any) {
    this.storageService.secureStorage.setItem(key, value);
  }
  // Get the json value from local
  getUser(key: string) {
    return this.storageService.secureStorage.getItem(key);
  }// Clear the local
  deleteUser(key: string) {
    // this.storageService.secureStorage.setItem("", "");
    return this.storageService.secureStorage.clear();
  }


setSessionTimeout() {
    timer( this.tiempo).subscribe(this.sessionTimer$);
  }
}
