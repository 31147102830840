<div class="diagloConfirmacion">
    <ng-container *ngIf="this.preguntar else procesado">

        <form [formGroup]="this.formulario">
            <div class="center mt-3" *ngIf="this.procesando === false else comprobando">
                <h6 style="text-align: center;"> {{titulo}} </h6>

                <p>{{msj}} </p>

                <mat-form-field class="example-full-width">
                    <input type="email" matInput required placeholder="Correo electrónico" autocomplete="off"
                        formControlName="email">
                    <mat-error *ngIf="formulario.controls['email'].errors?.email">
                        Debe ingresar un correo electrónico válido
                    </mat-error>
                    <mat-error *ngIf="formulario.controls['email'].errors?.required">

                        Falta el correo electrónico
                    </mat-error>
                </mat-form-field>

                <button mat-raised-button class="button-edit mr" (click)="restablecer()"> Restablecer</button>
                <button mat-raised-button class="button-cancel" (click)="confirmar(false)"> <i
                        class="fas fa-close mr-2"></i> Cancelar</button>
            </div>
            <ng-template #comprobando>
                <div style="max-width: 300;">
                    <h6 style="text-align: center;margin-bottom: 15px;"> Aguarde unos instantes mientras verificamos el
                        correo ingresado. </h6>

                    <div class="center">
                        <app-spinner [texto]="'Verificando correo'" [tamaño]="40"></app-spinner>
                    </div>
                </div>

            </ng-template>
        </form>

    </ng-container>

    <ng-template #procesado>

        <ng-container *ngIf="this.reseteada else NoSePudoEliminar">
            <h6 style="text-align: center;"> REVISE SU CORREO ELECTRÓNICO </h6>
            <p> <i class="fas fa-check-circle mr-2 color"></i> Se envió un enlace de restablecimiento de la contraseña
            </p>
            <div class="center mt-3">
                <button mat-raised-button class="button-success mr" (click)="confirmar(true)"> Aceptar</button>
                <button mat-raised-button class="button-cancel" (click)="confirmar(true)"> <i
                        class="fas fa-close mr-2"></i> Cerrar</button>
            </div>
        </ng-container>
        <ng-template #NoSePudoEliminar>
            <h6 style="text-align: center;"> NO SE PUDO RESTABLECER </h6>
            <p> <i class="fas fa-times-circle mr-2 colorRojo"></i> Ocurrió un error intentando restablecer la contraseña
            </p>
            <div class="center mt-3">
                <button mat-raised-button class="button-secondary mr" (click)="reintentar()"> Reintentar</button>
                <button mat-raised-button class="button-cancel" (click)="confirmar(true)"> <i
                        class="fas fa-close mr-2"></i> Cancelar</button>
            </div>
        </ng-template>
    </ng-template>

</div>