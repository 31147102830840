import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnInit, AfterViewInit {
  @ViewChild('spin', {static: false}) spinner: ElementRef;
  @ViewChild('contenedor', {static: false}) contenedor: ElementRef;

  @Input() texto: string;
  @Input() tamaño: number;
  @Input() arriba: boolean;
  @Input() textoColor: string = 'black';
 
  constructor(private render: Renderer2) { }

  ngOnInit(): void {
    this.setearTexto();
  }
  ngAfterViewInit() {
    this.aplicarEstilos();
}

  setearTexto() {
    if (this.texto === '') {
      //console.log("entros")
      this.texto = null;
    } else {
      !this.texto ? this.texto = 'Cargando' : null;
    }
  }

  aplicarEstilos() {
    if (this.tamaño) {
      this.render.setStyle(this.spinner.nativeElement , 'height', `${this.tamaño}px`);
      this.render.setStyle(this.spinner.nativeElement, 'width', `${this.tamaño}px`);
      // this.render.setStyle(this.spinner.nativeElement, 'margin', 0);
      this.render.setStyle(this.spinner.nativeElement, 'margin-top', '10px');
      this.render.setStyle(this.contenedor.nativeElement, 'display', 'inline-block');
      this.render.setStyle(this.contenedor.nativeElement, 'color', 'white');
      this.render.setStyle(this.contenedor.nativeElement , 'height', `80px`);

    }
  }

}
