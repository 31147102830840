<div id="login" #loginTemplate class="efecto" [style.min.height.px]="alto">
  <div class="container efecto " id="form-login">

    <div class="row">
      <div class="formulario" #formulario>
        <!-- SALTAR EL AUTOCOMPLETADO DE GOOGLE CROHOME, LOS 2 PRIMEROS text y password. display none no funca -->
        <!-- <div *ngIf="!recordarUsuario">
          <input type="text" style="opacity: 0; position: absolute;">
          <input type="password" style="opacity: 0; position: absolute;">
        </div> -->
        <!-- FORMULARIOS -->
        <form [formGroup]="this.formLogin">

          <!--SELECCIÓN LOGIN-->
          <div *ngIf="this.bienvenido">
            <div class="header">

              <img src="../../../assets/logoPrincipal.png" alt="" style="text-align: center; margin: 15px;" id="logoMinisterio">

              <h5 style="text-align: center;">
                PROGRAMA PRODUCTOS MISIONEROS
              </h5>
            </div>
            <br/>
            <div class="row">

              <div class="col-6 text-center">
                <button class="mat-icon-button-bienvenida" (click)="cargarComercio()" >
                  <h6>COMERCIO</h6>
                  <mat-icon id="icon-bienvenido-comercio" class="icon-bienvenido">store</mat-icon>
                </button>
              </div>

              <div class="col-6 text-center">
                <button class="mat-icon-button-bienvenida" (click)="cargarProveedor()" >
                  <h6>PROVEEDOR</h6>
                  <mat-icon id="icon-bienvenido-proveedor" class="icon-bienvenido">agriculture</mat-icon>
                </button>
              </div>
            </div>
          </div>

          <!-- LOGIN COMO COMERCIO. PROVEEDOR, ADMIN-->
          <div *ngIf="!this.bienvenido">
            <div class="header">
              <img src="../../../assets/logoPrincipal.png" alt="" id="logoMinisterio">
              <h1 style="margin-top: 15px;">Bienvenido a Productos Misioneros</h1>
              <ng-container *ngIf="this.userSistema else proveedorComercio">
                <p style="margin-bottom: 0;"><strong>Ingresa al sistema</strong> con tu Usuario y contraseña</p>
              </ng-container>
              <ng-template #proveedorComercio>
                <p style="margin-bottom: 0;"><strong>Ingresa al sistema</strong> con tu usuario y contraseña</p>
              </ng-template>

            </div>
            <div class="body text-center">

              <div class="col-12 mb-2">
                <mat-form-field class="example-full-width" id="inputUser">
                  <mat-label>Usuario (CUIT) </mat-label>
                  <input type="text" matInput autocomplete="off" formControlName="cuitCuil">
                  <!-- <mat-error *ngIf="formulario.controls['cuitCuil'].errors?.email">
                  Debe ingresar un {{this.userSistema ? 'Usuario' : 'DNI'}} válido
                </mat-error> -->
                  <mat-error *ngIf="formLogin.controls['cuitCuil'].errors?.required">
                    Ingrese su {{this.userSistema ? 'Usuario' : 'DNI'}}
                  </mat-error>
                  <mat-icon matSuffix>perm_identity</mat-icon>
                </mat-form-field>
              </div>

              <div class="col-12">

                <mat-form-field class="example-full-width" id="inputPass">
                  <mat-label>Contraseña</mat-label>
                  <input matInput type="{{typeInput}}" autocomplete="off" formControlName="password" id="pass" #pass>
                  <mat-error *ngIf="formLogin.controls['password'].errors?.required">
                    Ingrese la contraseña
                  </mat-error>
                  <mat-icon matSuffix (click)="mostrarPass()" *ngIf="!mostrarPassword" style="cursor: pointer">
                    visibility_off</mat-icon>
                  <mat-icon matSuffix (click)="mostrarPass()" *ngIf="mostrarPassword" style="cursor: pointer">visibility
                  </mat-icon>

                </mat-form-field>
                <p *ngIf="!logueando" style="text-align: left;">
                  <!-- matTooltip="La contraseña sera reestablecida a tu correo" -->

                </p>

              </div>
              <div class="col-12 text-left">
                <button id="login_lost_btn" type="button" class="btn btn-link" mat (click)="resetPassword()">¿Has
                  olvidado
                  tu contraseña?
                </button>
              </div>
              <div class="col-12 mt-2 mb-3 text-left enLinea">
                <mat-checkbox [checked]="this.recordarUsuario" (click)="$event.stopPropagation()"
                  (change)="recordarUser()"> </mat-checkbox>
                <p style="margin-top: -3px;">Recordar usuario</p>
              </div>

              <!-- INGRESAR -->
              <button mat-raised-button type="submit" class="button-add login_btn mt-2" (click)="login()"
                *ngIf="!logueando else spinner">
                Ingresar</button>

              <ng-template #spinner>
                <app-spinner [texto]="'Ingresando'" [tamaño]="40" [arriba]="true"></app-spinner>
              </ng-template>


              <!-- OLVIDO PASS -->

              <ng-container *ngIf="!this.userSistema && !logueando">


                <div class="nuevo">
                  <hr>

                  <p style="text-align: center; margin-bottom: 5px;"><strong>¿Eres nuevo?</strong></p>
                  <!-- matTooltip="Si todavia no tiene cuenta, presiona en el botón crear cuenta para registrarte." -->
                  <button mat-raised-button class="button-edit login_btn" id="login_register_btn"
                    (click)="this.registrarme()">
                    Registrarme</button>
                </div>
              </ng-container>

            </div>
          </div>

          <div class="footer row mt-4" *ngIf="!logueando">

            <div class="col-4 logos enLinea" style="text-align: right;" matTooltip="Marandu S.A. Comunicaciones">
              <a href="https://marandu.com.ar/" style="width: 100%;" target="_blank">
                <img src="../../../assets/marandu.png" alt="" id="logoMarandu">
              </a>
            </div>

            <div class="col-8 logos enLinea" style="text-align: center;" matTooltip="Ministerio ACMCI">
              <a href="https://acmci.misiones.gob.ar/programa-productos-misioneros/" style="width: 100%;" target="_blank">
                <img src="../../../assets/acmci-negro.png" alt="" id="logoMarandu">
              </a>
            </div>


          </div>
        </form>


      </div>
    </div>
  </div>
</div>
