import { ComercioModel } from '../modelo/comercio.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ServicesMSPService } from './abstractCRUD.service';
import { Observable } from 'rxjs';
import { PedidoModel } from '../modelo/pedido.model';
import { environment } from 'src/environments/environment';
import { ProveedorModel } from '../modelo/proveedor.model';

@Injectable({
  providedIn: 'root'
})

export class ComercioService extends ServicesMSPService<ComercioModel> {
  constructor(private http2: HttpClient) {
    super(http2, '/comercio');
  }
  getByUser(id: number): Observable<ComercioModel> {
    let url = this.urlBase + '/byUser/' + id.toString();
    return this.http2.get<ComercioModel>(url);
  }

  getOfertas(id: number): Observable<PedidoModel> {
    let url = this.urlBase + '/ofertas/' + id.toString();
    return this.http2.get<PedidoModel>(url);
  }

  getCertificado(id: number): Observable<Blob>  {
    let url = environment.urlBase+ environment.vApi +'/report/certificadoComercio/' + id.toString();
    return this.http2.get(url, { responseType: 'blob' });
  }

  getByCuit(cuit: number): Observable<any> {
    let url = environment.urlBase+ '/byCuit/' + cuit.toString();
    return this.http2.get<any>(url);
  }

  concretarPedido(idPedido: number,proveedor: ProveedorModel): Observable<any> {
    let parametros = new HttpParams()
      parametros = parametros.append('idOferta', idPedido.toString())
      parametros = parametros.append('idProveedor', proveedor.id.toString())
    let url = environment.urlBase+ environment.vApi + '/pedido/concretar';
    //console.log(proveedor)
    return this.http2.put<any>(url,proveedor,{params: parametros});
  }



}
