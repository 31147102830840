<div id="pagina404" class="efecto" [style.height.px]="alto">
  <div class="container efecto " id="form-login">
    <div class="row">
      <div class="formulario">


        <div class="col-12">
          <div class="header">
            <img src="../../../../assets/logoPrincipal-b.png" alt="" id="logoMinisterio">

            <h1> Página no encontrada</h1>
            <h2> Error 404</h2>

            <hr>
            <p> Ir a la página de <a href=""> inicio</a></p>
          </div>
        </div>
        <div class="body">



          <div class="col-12">
            <h6>¿Que pasó?</h6>
            <p>Un estado de error 404 implica que no se pudo encontrar el archivo o la página que está buscando.</p>
          </div>
          <div class="col-12">
            <h6>¿Que puedo hacer?</h6>
            <p class="lead">Si eres un visitante del sitio</p>
            <p>Utilice el botón de retroceso de su navegador y compruebe que está en el lugar correcto. Si necesita asistencia inmediata, envíenos un correo electrónico.</p>
            <p class="lead">Si eres el dueño del sitio</p>
             <p>Compruebe que se encuentra en el lugar correcto y póngase en contacto con el proveedor de su sitio web si cree que se trata de un error.</p>
         </div>

        </div>

        <div class="footer mt-5">
          <div class="logos enLinea" style="text-align: center;">

            <img src="../../../assets/marandu-logo-letras-blanco.png" alt="" id="logoMarandu">

          </div>
        </div>
      </div>
    </div>

  </div>
</div>
