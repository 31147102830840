import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServicesMSPService } from './abstractCRUD.service';
import { MunicipioModel } from '../modelo/municipio.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class EstadoAPIService extends ServicesMSPService<any> {
  constructor(http: HttpClient) {
    super(http, environment.estadoAPI);
  }}