import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthService } from './servicios/auth.service';
import { PaginaNoEncontrada404Component } from './components/shared/pagina-no-encontrada404/pagina-no-encontrada404.component';
import { LayoutExternoComponent } from './components/layout-externo/layout-externo.component';
import { LoginComponent } from './components/Auth/login/login.component';

const routes: Routes = [
  // restorePass activate
  { path: '', redirectTo: 'inicio/bienvenido', pathMatch: 'full',  },
  {
    path: 'productosmisioneros', // login para proveedores
    loadChildren: () => import('./components/Auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'inicio/:nombre',  // login, segun nombre accede al lso distinto sistemas
    component: LoginComponent,
    loadChildren: () => import('./components/Auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'componente', component: LayoutExternoComponent,
    loadChildren: () => import('./components/layout-externo/layout-externo.module').then(m => m.LayoutExternoModule)
  },
  {
    path: 'sistema',
    canActivate: [AuthService],
    loadChildren: () => import('./components/sistema/sistema.module').then(m => m.SistemaModule)
  },
  { path: '**', component: PaginaNoEncontrada404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
      scrollPositionRestoration: 'top'
    },
  ),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
